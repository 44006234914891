<template>
  <div class="container">
    <div class="breadcrumb">
      <p class="topic_path m-0">
        <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{
            $t("account.default.pageTitle")
          }}
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i> {{ $t("account.editWithoutPassword.sexChange") }}
      </p>
    </div>
    <div class="card text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4">{{ $t("account.editWithoutPassword.sexChange") }}</h3>
        </div>
        <div class="mt-0 mt-sm-4 pl-3">
          <div class="form-check form-check-inline col-12 col-sm-2">
            <input id="genderFemale" v-model="gender" class="form-check-input" name="gender" type="radio" value="2">
            <label class="form-check-label" for="genderFemale">{{ $t("account.editWithoutPassword.female") }}</label>
          </div>
          <div class="form-check form-check-inline col-12 col-sm-2">
            <input id="genderMale" v-model="gender" class="form-check-input" name="gender" type="radio" value="1">
            <label class="form-check-label" for="genderMale">{{ $t("account.editWithoutPassword.male") }}</label>
          </div>
          <div class="form-check form-check-inline col-12 col-sm-3">
            <input id="genderDoNotAnswer" v-model="gender" class="form-check-input" name="gender" type="radio"
                   value="3">
            <label class="form-check-label" for="genderDoNotAnswer">{{
                $t("account.editWithoutPassword.doNotAnswer")
              }}</label>
          </div>
        </div>
        <div class="row">
          <div v-show="errors['gender']" id="err_sex" class="form-error"><br/>{{ errors['gender'] }}</div>
        </div>
        <div class="row m-t-20">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn"
                    @click="$router.push('account')">
              {{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    v-on:click="doChange">
              {{ $t("account.editWithPassword.btnChange") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {SetUpdateUser} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import {mapGetters} from "vuex";

export default {
  name: "EditSex",
  data() {
    return {
      gender: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ""
      params.firstNameUpdate = false
      params.lastName = ""
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = Number(this.gender)
      params.genderUpdate = true
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    async doChange(step) {
      this.errors = [];
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        const message = this.$t("account.editWithoutPassword.toastSuccessChangeSex")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.gender = jsonObject.gender
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getUser()
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }

  .border-top {
    border: 0px !important;
  }
}
</style>
